import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1198044d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-xs-12 col-sm-8" }
const _hoisted_3 = {
  key: 0,
  class: "mb-2"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "title" }
const _hoisted_6 = {
  key: 1,
  class: "mt-3"
}
const _hoisted_7 = { class: "col-xs-12 col-sm-4 order-first order-sm-last" }
const _hoisted_8 = { class: "lang-select-and-page" }
const _hoisted_9 = {
  key: 0,
  class: "lang-select mb-3"
}
const _hoisted_10 = {
  key: 1,
  class: "page-indicator"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HakoMultiselect = _resolveComponent("HakoMultiselect")!
  const _component_HakoContentBlock = _resolveComponent("HakoContentBlock")!

  return (_openBlock(), _createBlock(_component_HakoContentBlock, {
    "no-header": true,
    class: "mb-3"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.surveyId && !_ctx.hideLogo)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("img", {
                  class: "survey-logo",
                  src: `${_ctx.cBaseUrl}/Survey/Logo/${_ctx.selectedLanguage!}/${_ctx.surveyId}`,
                  onError: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hideLogo = true))
                }, null, 40, _hoisted_4)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.title), 1),
          (_ctx.ingress)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.ingress), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            (_ctx.cLanguages && _ctx.cLanguages.length > 1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createVNode(_component_HakoMultiselect, {
                    options: _ctx.cLanguages,
                    "disallow-remove": true,
                    modelValue: _ctx.selectedLanguage,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedLanguage) = $event)),
                    "use-avatar": false,
                    placeholder: ""
                  }, null, 8, ["options", "modelValue"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.pageAmount > 1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pageAmount, (index) => {
                    return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                      (index == _ctx.currentPage)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            style: _normalizeStyle(
									'background-color:' +
									_ctx.pageIndicatorColor +
									';' +
									'border-color:' +
									_ctx.pageIndicatorColor +
									';'
								),
                            class: "dot"
                          }, "  ", 4))
                        : (_openBlock(), _createElementBlock("span", {
                            key: 1,
                            style: _normalizeStyle('border-color:' + _ctx.pageIndicatorColor + ';'),
                            class: "dot"
                          }, "   ", 4))
                    ], 64))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}