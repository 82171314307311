import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-bf392e10"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "single-select-radio" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cOptions, (option) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: option.value
      }, [
        _withDirectives(_createElementVNode("div", {
          class: "item item-active",
          onClick: _cache[0] || (_cache[0] = ($event: any) => {
					{
						_ctx.answer = null;
						_ctx.userHasAnswered = true;
					}
				})
        }, _toDisplayString(option.text), 513), [
          [_vShow, _ctx.answer == option.value]
        ]),
        _withDirectives(_createElementVNode("div", {
          class: "item",
          onClick: ($event: any) => {
					{
						_ctx.answer = option.value;
						_ctx.userHasAnswered = true;
					}
				}
        }, _toDisplayString(option.text), 9, _hoisted_2), [
          [_vShow, _ctx.answer != option.value]
        ])
      ], 64))
    }), 128))
  ]))
}