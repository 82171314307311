import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createBlock as _createBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-56a0c53c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 0,
  class: "test-answer"
}
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { class: "invalid-page-title" }
const _hoisted_5 = { class: "invalid-page-text" }
const _hoisted_6 = { class: "question-info" }
const _hoisted_7 = ["id"]
const _hoisted_8 = {
  key: 0,
  class: "required text-muted"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = {
  key: 0,
  class: "question-content mt-3"
}
const _hoisted_12 = { key: 1 }
const _hoisted_13 = ["for"]
const _hoisted_14 = { class: "buttons my-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SurveyHeader = _resolveComponent("SurveyHeader")!
  const _component_HakoSpinner = _resolveComponent("HakoSpinner")!
  const _component_HakoTextArea = _resolveComponent("HakoTextArea")!
  const _component_HakoContentBlock = _resolveComponent("HakoContentBlock")!
  const _component_HakoButton = _resolveComponent("HakoButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.cIsTestAnswer)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t("thisIsTestAnswer")), 1))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(_component_SurveyHeader, {
      title: _ctx.surveyName(_ctx.survey),
      languages: _ctx.survey?.languages,
      langFromUrl: _ctx.langFromUrl,
      pageIndicatorColor: _ctx.cPageIndicatorColor,
      currentPage: _ctx.currentPage,
      pageAmount: _ctx.cPageAmount,
      ingress: _ctx.cIngress,
      surveyId: _ctx.survey?.id
    }, null, 8, ["title", "languages", "langFromUrl", "pageIndicatorColor", "currentPage", "pageAmount", "ingress", "surveyId"]), [
      [_vShow, _ctx.survey]
    ]),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_HakoSpinner, { key: 1 }))
      : (_ctx.invalidCode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("surveyNotFoundTitle")), 1),
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("surveyNotFoundText")), 1)
          ]))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 3 }, _renderList(_ctx.cPages, (page, index) => {
            return (_openBlock(), _createBlock(_Transition, {
              key: page.id,
              name: _ctx.slideDirClass,
              mode: "out-in"
            }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("div", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(page.questions!, (question) => {
                    return (_openBlock(), _createBlock(_Transition, {
                      key: question.id,
                      name: "next-page-fade"
                    }, {
                      default: _withCtx(() => [
                        _withDirectives(_createVNode(_component_HakoContentBlock, {
                          noHeader: true,
                          class: "mt-3"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_6, [
                              _createElementVNode("div", {
                                class: "question-title",
                                id: 'question' + question.id
                              }, [
                                _createTextVNode(_toDisplayString(_ctx.getTextInCurrLang(question.translations, "question")) + " ", 1),
                                (question.isRequired)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t("required")), 1))
                                  : _createCommentVNode("", true)
                              ], 8, _hoisted_7),
                              (_ctx.getTextInCurrLang(question.translations, 'description'))
                                ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.getTextInCurrLang(question.translations, "description")), 1))
                                : _createCommentVNode("", true),
                              (
												question.type?.technicalName == 'multiLimited' &&
												question.maxOptionPicks
											)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t("maxOptionPicks") + ": " + question.maxOptionPicks), 1))
                                : _createCommentVNode("", true)
                            ]),
                            (_ctx.isAnswerType(question.type))
                              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                  (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getComponentName(question.typeId)), {
                                    questionData: question,
                                    lang: _ctx.$i18n.locale,
                                    activations: 
												_ctx.activations.filter(
													(x) =>
														question.options?.some((o) => o.id == x.optionId) ||
														question.id == x.activatingQuestionId
												)
											,
                                    answerRow: 
												_ctx.answerDto?.answerRows?.find((x) => x.questionId == question.id)
											,
                                    onActivatedActivations: ($event: any) => (
												_ctx.updateActivatedActivations($event, question.id!)
											),
                                    onSave: ($event: any) => (_ctx.saveAnswerRow(question, $event))
                                  }, null, 40, ["questionData", "lang", "activations", "answerRow", "onActivatedActivations", "onSave"]))
                                ]))
                              : _createCommentVNode("", true),
                            (question.asksComments)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                  _createElementVNode("label", {
                                    for: 'comments' + question.id
                                  }, _toDisplayString(_ctx.$t("comments")) + ":", 9, _hoisted_13),
                                  _createVNode(_component_HakoTextArea, {
                                    id: 'comments' + question.id,
                                    modelValue: _ctx.answerDto!.answerRows!.find((x) => x.questionId == question.id)!.commentText,
                                    "onUpdate:modelValue": ($event: any) => ((_ctx.answerDto!.answerRows!.find((x) => x.questionId == question.id)!.commentText) = $event),
                                    onChange: ($event: any) => (_ctx.saveComment(question.id!))
                                  }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "onChange"])
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1536), [
                          [_vShow, _ctx.checkIfQuestionActivated(question.id!)]
                        ])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128)),
                  _createElementVNode("div", _hoisted_14, [
                    (index > 0)
                      ? (_openBlock(), _createBlock(_component_HakoButton, {
                          key: 0,
                          class: "previous-button",
                          theme: "secondary",
                          onClick: ($event: any) => (_ctx.previousPage(index))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("previousPage")), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      : _createCommentVNode("", true),
                    (index + 1 < _ctx.cPages.length)
                      ? (_openBlock(), _createBlock(_component_HakoButton, {
                          key: 1,
                          class: "next-button",
                          theme: "secondary",
                          onClick: ($event: any) => (_ctx.nextPage(index))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("nextPage")), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      : (_openBlock(), _createBlock(_component_HakoButton, {
                          key: 2,
                          theme: "success",
                          class: "next-button",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.finished()))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("finished")), 1)
                          ]),
                          _: 1
                        }))
                  ])
                ], 512), [
                  [_vShow, _ctx.showPage(page)]
                ])
              ]),
              _: 2
            }, 1032, ["name"]))
          }), 128))
  ]))
}