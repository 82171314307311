
import { i18n } from "@/i18n";
import axios from "axios";
import { onBeforeMount } from "vue";
import { ref } from "vue";
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import SurveyAnswer from "@/components/SurveyAnswer.vue";

export default defineComponent({
	components: { SurveyAnswer },
	setup() {
		const route = useRoute();

		onBeforeMount(() => {
			getThankYouText();
			getShowSummary();
		});

		const thankYouText = ref("");
		const loading = ref(true);
		const showAnswers = ref(false);
		const showAnswersModal = ref(false);

		const getThankYouText = async () => {
			try {
				const response = await axios.get<string>(
					`survey/thankYouText/${route.params["code"]}/${i18n.global.locale}`,
					{
						headers: {
							organization: route.params["org"],
						},
					}
				);
				thankYouText.value = response.data;
				loading.value = false;
			} catch (ex: unknown) {
				console.log("Problem fetching thank you -text");
				loading.value = false;
			}
		};

		const getShowSummary = async () => {
			try {
				const response = await axios.get<boolean>(
					`survey/showSummary/${route.params["code"]}`,
					{
						headers: {
							organization: route.params["org"],
						},
					}
				);
				showAnswers.value = response.data;
			} catch (ex: unknown) {
				console.log("Problem fetching show summary -data");
				loading.value = false;
			}
		};
		return {
			thankYouText,
			loading,
			showAnswers,
			showAnswersModal,
		};
	},
});
