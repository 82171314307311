
import { i18n } from "@/i18n";
import AnswerForSingleQuestion from "@/components/AnswerForSingleQuestion.vue";
import {
	SurveyAnswerDto,
	SurveyQuestionTranslationDto,
	SurveyQuestionTypeTranslationDto,
	SurveyDto,
} from "@/dto";
import { NotificationManager } from "@hakosalo/hakolib";
import { computed, defineComponent, onBeforeMount, PropType, ref } from "vue";
import axios from "axios";
import store from "@/store";

export default defineComponent({
	props: {
		code: {
			type: String as PropType<string>,
		},

		org: {
			type: String as PropType<string>,
		},
	},

	components: {
		AnswerForSingleQuestion,
	},

	setup(props) {
		const { t } = i18n.global;

		onBeforeMount(async () => {
			if (cIsTestAnswer.value) {
				await loadTestAnswerFromStore();
			} else {
				await getAnswer();
			}
		});

		const loading = ref(true);
		const answer = ref<SurveyAnswerDto>({} as SurveyAnswerDto);

		const getAnswer = async () => {
			try {
				const resp = await axios.get<SurveyAnswerDto>(`survey/answerSummary/${props.code}`, {
					headers: {
						organization: props.org,
					},
				});

				if (resp.data) {
					answer.value = resp.data;
					loading.value = false;
				} else {
					NotificationManager.quickDanger(
						t("error_loading_data"),
						t("survey.delivery.problemGettingSurveys")
					);
					loading.value = false;
				}
			} catch (ex) {
				console.log(ex);
			}
		};

		const questionTitle = (translations: SurveyQuestionTranslationDto[]) => {
			let resp = "";
			[i18n.global.locale, "fi", "sv", "en"].forEach((lang) => {
				if (!resp) {
					let translation = translations.find((x) => x.languageCode == lang);
					if (translation?.question) resp = translation.question;
				}
			});

			return resp;
		};

		const questionDescription = (translations: SurveyQuestionTranslationDto[]) => {
			let resp = "";
			[i18n.global.locale, "fi", "sv", "en"].forEach((lang) => {
				if (!resp) {
					let translation = translations.find((x) => x.languageCode == lang);
					if (translation?.description) resp = translation.description;
				}
			});

			return resp;
		};

		const questionType = (translations: SurveyQuestionTypeTranslationDto[]) => {
			let resp = "";
			[i18n.global.locale, "fi", "sv", "en"].forEach((lang) => {
				if (!resp) {
					let translation = translations.find((x) => x.languageCode == lang);
					if (translation?.name) resp = translation.name;
				}
			});

			return resp;
		};

		const surveyName = (survey: SurveyDto) => {
			if (!survey) return "";

			if (survey.internalName) return survey.internalName;

			let name = survey.translations.find((x) => x.languageCode == i18n.global.locale)?.name;
			if (name) return name;

			["fi", "sv", "en"].forEach((lang) => {
				name = survey.translations.find((x) => x.languageCode == lang)?.name;
				if (name) return name;
			});

			return t("nameNotFound");
		};

		const questionWasAnswered = (questionId: number) => {
			if (cIsTestAnswer.value) {
				if (answer.value.answerRows?.some((x) => x.questionId == questionId && x.answerId == 0))
					return true;
			} else {
				if (answer.value.answerRows?.some((x) => x.questionId == questionId)) return true;
			}

			return false;
		};

		const cIsTestAnswer = computed((): boolean => {
			if (props.code && props.code.length == 15) return true;

			return false;
		});

		const loadTestAnswerFromStore = async () => {
			const storeAnswer = store.getters.getTestAnswer as SurveyAnswerDto | null;
			if (storeAnswer?.surveyId) {
				try {
					const resp = await axios.get<SurveyDto>(
						`survey/WithQuestions/${storeAnswer.surveyId}`,
						{
							headers: {
								organization: props.org,
							},
						}
					);

					if (resp.data) {
						answer.value = storeAnswer;
						answer.value.survey = resp.data;
						loading.value = false;
					} else {
						NotificationManager.quickDanger(
							t("error_loading_data"),
							t("survey.delivery.problemGettingSurveys")
						);
						loading.value = false;
					}
				} catch (ex) {
					console.log(ex);
				}
			}

			loading.value = false;
		};

		return {
			loading,
			answer,
			questionTitle,
			questionDescription,
			questionType,
			surveyName,
			questionWasAnswered,
		};
	},
});
